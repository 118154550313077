import { ENTER, COMMA, SPACE } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Asset } from 'aws-sdk/clients/rekognition';
import { Subscription, Subject, Observable } from 'rxjs';
import { startWith, map, takeUntil, filter } from 'rxjs/operators';
import { CcmcApiService } from '../../services/ccmc-api.service';
import { AmplifyService } from '../../services/amplify.service';
import { CCMCSelectedFieldService } from '../../services/selected-field.service';
import { SpinnerService } from '../../services/spinner.service';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SalesforceService } from 'src/@ccmc/services/salesforce.service';

@Component({
  selector: 'app-add-user-popup-dialog',
  templateUrl: './add-user-popup-dialog.component.html',
  styleUrls: ['./add-user-popup-dialog.component.scss']
})
export class AddUserPopupDialogComponent implements OnInit {
  selectable = true;
  removable = true;
  addOnBlur = true;
  isNXTsoft = false;
  isNXTsoftDev = false;
  isNXTsoftOperations = false;
  assetsCtrl = new FormControl();
  assetsLists = new FormControl();
  private selectedUserSub: Subscription;
  public selectedUser: any;
  adminAssets: any;
  assetsSplit: any;
  unsubscribe: Subject<any> = new Subject();
  assetsArray: any;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];
  filteredAssets: Observable<any[]>;
  @ViewChild('assetsInput', { static: false }) assetsInput: ElementRef;
  currentUsersView: any;
  assetsToShow = [];
  currentSelected = [];
  assetIDList: any = [{ SalesforceAssetId: '' }];
  usersPinned: any;
  data: any;
  assets: Asset[] = [];
  spinnerSub: Subscription;
  showSpinner: boolean = false;
  fullAssetsTable: any;
  availableAssets: any = [];
  
  tmpUser = {
    email: '',
    accountID: '',
    assetID: '',
    contactFirstName: '',
    contactLastName: ''
  };
  currentUsersAccountID = '';

  constructor(
    private selectedFieldService: CCMCSelectedFieldService,
    private spinnerService: SpinnerService,
    private ccmcApiService: CcmcApiService,
    private adminApiService: CcmcApiService,
    public dialogRef: MatDialogRef<AddUserPopupDialogComponent>,
    private router: Router,
    private amplifyService: AmplifyService,
    private salesforceService: SalesforceService,
    private snackBar: MatSnackBar,
    private zone: NgZone
  ) {}

  ngOnInit() {
    this.spinnerSub = this.spinnerService.spinner
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(spinner => {
        // //console.log(spinner);

        this.showSpinner = spinner;
      });
    this.currentUsersAccountID = this.amplifyService.getAccountID();
    if (this.currentUsersAccountID) {
      this.tmpUser.accountID = this.currentUsersAccountID;
      this.loadAssets(this.currentUsersAccountID);
      // console.log();
    }
    this.isNXTsoft = this.amplifyService.isNXTsoft;
    this.isNXTsoftDev = this.amplifyService.isNXTsoftDev;
    this.isNXTsoftOperations = this.amplifyService.isNXTsoftOperations;
  }

  /**
   * Updates Assets
   * @description Updates the currentSelected user assets
   * @memberof EditUserAssetsComponent
   */

  closeDialog() {
    this.dialogRef.close();
  }
  loadAssets(accountID: any) {
    this.spinnerService.setShowSpinner(true);
    this.availableAssets = [];
    this.salesforceService.getAccountData().subscribe(result => {
    });
    this.ccmcApiService.getAccountData(accountID).subscribe((result: any) => {
      if (result && result.content && result.content.StatusFlag) {
        const parsedResult: any = JSON.parse(result.content.StatusMessage);
        // console.log(parsedResult);
        // console.log(parsedResult.Assets.length);
        let i = 0;
        for (let asset of parsedResult.Assets) {
          console.log(asset);
          this.ccmcApiService
            .getDynamoDBItem('assets', asset.SalesforceAssetId, 'assetId')
            .subscribe(result => {
              console.log(result);
              if (result) {
                console.log("asset:", asset);
                const parsedRes = JSON.parse(JSON.stringify(result));
                const assetExists = this.availableAssets.some((a: any) => a.SalesforceAssetId === parsedRes.assetId);
                if (!assetExists) {
                    this.availableAssets.push(asset);
                }
              }
            
              if (i == parsedResult.Assets.length - 1) {
                // console.log('last run through');
                // console.log(this.availableAssets.length);
                if (this.availableAssets.length == 0) {
                  this.spinnerService.setShowSpinner(false);

                  this.snackBar.open(
                    `No Assets from ${accountID} AccountID exist `,
                    'Okay'
                  );
                }
                this.spinnerService.setShowSpinner(false);
              }
              i++;
              // console.log(i);
            });
        }
      } else {
        this.spinnerService.setShowSpinner(false);

        this.snackBar.open('Please enter a valid AccountID', 'Okay');
      }
    });
  }

  setAssetIDs() {
    let tmpAsset = '';

    for (let i = 0; i < this.assetIDList.length; i++) {
      if (i === 0) {
        tmpAsset = this.assetIDList[i].SalesforceAssetId;
      } else {
        tmpAsset = tmpAsset + ', ' + this.assetIDList[i].SalesforceAssetId;
      }
    }
    this.tmpUser.assetID = tmpAsset
  }

  save() {
    // params sent
    const params = {
      email: this.tmpUser.email.toLowerCase(),
      accountID: this.tmpUser.accountID,
      assetID: this.tmpUser.assetID,
      username: this.tmpUser.email.toLowerCase()
    };
    const salesforceParams = {
      contact: {
        accountID: this.tmpUser.accountID,
        contactEmail: this.tmpUser.email.toLowerCase(),
        contactFirstName: this.tmpUser.contactFirstName,
        contactID: '',
        contactLastName: this.tmpUser.contactLastName,
        contactPhone: '',
        contactStatus: 'Active',
        contactTitle: '',
        contactRole: ''
      },
      type: 'create'
    };
    console.log(params);
    this.ccmcApiService.createUser(params).subscribe(res => {
      const result = JSON.parse(JSON.stringify(res));
      //console.log(result);
      if (result.errorMessage) {
        // Check if asset contains includes already exists
        if (result.errorMessage.includes('User account already exists')) {
          this.snackBar.open('User account already exists', 'Okay');
        } else {
          this.snackBar.open('Error Creating User', 'Okay');
        }
      } else {
        this.snackBar.open(result.statusMessage, 'Okay');
        this.ccmcApiService.callRefreshUsers();
      }
    });
    this.salesforceService
      .getContactID(this.tmpUser.email.toLowerCase())
      .subscribe(user => {
        console.log(user);
        if (user.StatusMessage.includes('not found')) {
          this.salesforceService
            .addNewContactInfo(salesforceParams)
            .subscribe(res => {
              console.log(res);
            });
        }
      });
    this.dialogRef.close();
  }
}
