<div class="collateral-search-container">
  <div class="collateral-search-top-section">
    <!--Collateral DropDown-->
    <div class="collateral-dropdown" id="dropdown">
      <label for="party-name">
        <div class="div-collateral-dropdown">Collateral Name Search</div>
        <select name="existing-party-name" id="collateral-dropdown" #existing>
          <option *ngFor="let CIF of parties" value="{{ CIF }}">
            {{ CIF }}
          </option>
        </select>
      </label>
    </div>

    <!--Top Row of Buttons-->
    <div class="top-button-container" id="top-button-container">
      <!--Search Button-->
      <button
        class="search-button"
        mat-raised-button
        (click)="searchExisting(existing.value)"
        matTooltip="This button will Search names associated with the collateral."
        matTooltipClass="tooltip-color"
        id="search-button"
      >
        <mat-icon class="search-icon">find_in_page</mat-icon>
        <span class="button-text">&nbsp;Search</span>
      </button>

      <!--Show Button-->
      <button
        class="show-button"
        (click)="showDetails()"
        mat-raised-button
        matTooltip="This will Show detailed information on a selected collateral"
        matTooltipClass="tooltip-color"
        id="show-button"
      >
        <mat-icon class="show-icon">info</mat-icon
        ><span class="button-text"> &nbsp; Show Details</span>
      </button>

      <!--New Help Button with Tutorial carousel-->

      <!--
          <button class="collateral-search__button-container--button"  mat-raised-button style=" box-shadow:0 3px 3px 0 rgb(85, 85, 85); color:white; padding-left: 6px; background-color: #3b3b3b; border-radius: 4px; margin-left:8px;">
            <mat-icon style="color:white; padding-left: 6px;">help</mat-icon> &nbsp; Help</button>
          -->
    </div>
  </div>

  <!--Top Table-->
  <div class="collateral-search-table-container" id="top-table">
    <mat-table
      #collateralSearchSort="matSort"
      class="collateral-search-table"
      #collateralSearchTable
      [dataSource]="collateralSearchDataSource"
      matSort
    >
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
        <mat-cell *matCellDef="let element">
          <mat-checkbox
            type="checkbox"
            #collateralExistingChkbx
            class="collateral-search-checkbox"
            (change)="selectingCollateral(collateralExistingChkbx, element)"
          ></mat-checkbox>
          <div class="checkmark" [ngStyle]="{'display': element?.name ? '' : 'none' }">{{ element?.name }}</div>
          <div class="checkmark" [ngStyle]="{'display': element?.name ? 'none' : '' }">{{ element?.collateralID }}</div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="cell-text"
          >Collateral Type</mat-header-cell
        >
        <mat-cell *matCellDef="let element">{{ element?.type }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="cell-text"
          >Collateral Description</mat-header-cell
        >
        <mat-cell *matCellDef="let element">{{
          element?.description
        }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="cell-text"
          >Collateral Status</mat-header-cell
        >
        <mat-cell *matCellDef="let element">{{ element?.status }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="assignedParty">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="cell-text"
          >Assigned Party</mat-header-cell
        >
        <mat-cell *matCellDef="let element"
          >{{ element?.assignedParty }}
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="tableColumns; sticky: true"
      ></mat-header-row>
      <mat-row *matRowDef="let row; columns: tableColumns"></mat-row>
    </mat-table>
  </div>

  <!--Middle Row-->
  <!--Assign DropDown-->
  <div class="assignment-dropdown" id="dropdown">
    <label for="party-name-assignment">
      <div class="div-assignment-dropdown">Assign Collateral to Party</div>
      <select name="party-name-assignment" id="assignment-dropdown" #assignment>
        <option *ngFor="let CIF of parties" value="{{ CIF }}">{{ CIF }}</option>
      </select>
    </label>

    <!--Middle Row of Buttons-->
    <div class="middle-button-container" id="middle-button-container">
      <!--Assign Button-->
      <button
        class="assign-button"
        (click)="assignToSelected(assignment.value)"
        [class.disabled]="assignPartyDisabled"
        mat-raised-button
        matTooltip="Use this button to Assign existing collateral."
        matTooltipClass="tooltip-color"
        id="assign-button"
        color="accent"
      >
        <mat-icon class="assign-button-icon">check_box</mat-icon
        ><span class="button-text">&nbsp;Assign</span>
      </button>

      <!--Add Button-->
      <button
        class="add-button"
        matTooltip="Use this button, If you need to Add new collateral."
        matTooltipClass="tooltip-color"
        (click)="addCollateral(assignment.value)"
        mat-raised-button
        id="add-button"
      >
        <mat-icon class="add-button-icon">add_circle_outline</mat-icon
        ><span class="button-text"> &nbsp;Add Collateral</span>
      </button>

      <!--Remove Button-->
      <button
        class="remove-button"
        [class.disabled]="assignPartyDisabled"
        (click)="remove()"
        mat-stroked-button
        id="remove-button"
        color="primary"
      >
        <mat-icon class="remove-button-icon">cancel</mat-icon
        ><span class="button-text"> &nbsp;Remove</span>
      </button>

      <!--Remove All Button-->
      <button
        class="remove-all-button"
        [class.disabled]="
          collateralAssignmentDataSource.data &&
          collateralAssignmentDataSource.data.length === 0
        "
        (click)="removeAll()"
        mat-stroked-button
        id="remove-all-button"
        color="primary"
      >
        <mat-icon class="remove-all-button-icon">delete_forever</mat-icon
        ><span class="button-text"> &nbsp;Remove All&nbsp;</span>
      </button>
    </div>
  </div>

  <!--Bottom Table-->
  <div class="assignment-table-container" id="bottom-table">
    <mat-table
      class="assignment-table"
      [dataSource]="collateralAssignmentDataSource"
      #bottomTable
      cdkDropList
      [cdkDropListData]="collateralAssignmentDataSource"
      (cdkDropListDropped)="dropTable($event)"
    >
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element">
          <mat-checkbox
            #collateralAssignChkbx
            class="assignment-table-checkbox"
            (change)="addToAssign(collateralAssignChkbx, element)"
          ></mat-checkbox>
          <div class="checkmark" [ngStyle]="{'display': element?.name ? '' : 'none' }">{{ element?.name }}</div>
          <div class="checkmark" [ngStyle]="{'display': element?.name ? 'none' : '' }">{{ element?.collateralID }}</div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef class="cell-text"
          >Collateral Type</mat-header-cell
        >
        <mat-cell *matCellDef="let element">{{ element.type }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef class="cell-text"
          >Collateral Description</mat-header-cell
        >
        <mat-cell *matCellDef="let element">{{
          element?.description
        }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef class="cell-text"
          >Collateral Status</mat-header-cell
        >
        <mat-cell *matCellDef="let element">{{ element.status }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="assignedParty">
        <mat-header-cell *matHeaderCellDef class="cell-text"
          >Assigned Party</mat-header-cell
        >
        <mat-cell *matCellDef="let element">
          {{ element.assignedParty }}
          <span class="fill-remaining-space"></span>
          <mat-icon cdkDragHandle>reorder</mat-icon>
        </mat-cell>
      </ng-container>

      <mat-header-row
        *matHeaderRowDef="bottomTableColumns; sticky: true"
      ></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: bottomTableColumns"
        cdkDrag
        [cdkDragData]="row"
      ></mat-row>
    </mat-table>
  </div>

  <!--Bottom Row of Buttons-->
  <div class="bottom-button-container" id="bottom-button-container">
    <!--Cancel-Button"-->
    <button
      class="cancel-button"
      mat-stroked-button
      (click)="closeDialog(false)"
      id="cancel-button"
    >
      Cancel
    </button>

    <!--Send Button-->
    <button
      class="send-loan-button"
      color="accent"
      [ngClass]="{ disabled: checkContinueDisabled() }"
      [disabled]="checkContinueDisabledBoolean"
      (click)="sendLoan()"
      mat-raised-button
      matTooltip="This button will Send the loan the CORE."
      matTooltipClass="tooltip-color"
    >
      <span class="button-text">Send&nbsp;</span>
      <mat-icon class="send-button-icon">send</mat-icon>
    </button>
  </div>

  <!--Spinner-->
  <div class="spinner-container" *ngIf="showSpinner">
    <div class="new-spinner">
      <div class="outer-ring"></div>
      <div class="middle-ring"></div>
      <div class="inner-ring"></div>
    </div>
  </div>
</div>
