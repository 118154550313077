import { Component, HostListener, OnInit } from '@angular/core';
import { CCMCNavigationService } from 'src/@ccmc/components/navigation/navigation.service';
import { CCMCSplashScreenService } from 'src/@ccmc/services/splash-screen.service';
import { AuthService } from './auth/auth.service';
import { AuthGuard } from './auth/auth.guard';
import { CookieService } from 'ngx-cookie-service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ActivatedRoute, Router } from '@angular/router';
import { CryptrService } from 'src/@ccmc/services/cryptr.service';
import { AvatarService } from 'src/@ccmc/services/avatar.service';
import { AmplifyService } from 'src/@ccmc/services/amplify.service';
import { DatadogRUMService } from 'src/@ccmc/services/datadogRUM.service';
import { debounce } from 'lodash';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  jwtHelper;
  userActivity: any;
  userInactive: Subject<any> = new Subject();
  constructor(
    // tslint:disable-next-line:no-shadowed-variable
    private ccmcNavigationService: CCMCNavigationService,
    private ccmcSplashScreen: CCMCSplashScreenService,
    private authService: AuthService,
    private authGuard: AuthGuard,
    private cookieService: CookieService,
    private cryptrService: CryptrService,
    private router: Router,
    private avatarService: AvatarService,
    private route: ActivatedRoute,
    private amplifyService: AmplifyService,
    private datadogRUMService: DatadogRUMService
  ) {
    this.amplifyService.amplifyConfigure();
    this.jwtHelper = new JwtHelperService();
    this.datadogRUMService.initDatadog();
    this.amplifyService
      .getCurrentUser()
      .then(result => {
        const parsedResult = JSON.parse(JSON.stringify(result));
        console.log(parsedResult);
        if (parsedResult.success) {
          const decodedIdToken = this.jwtHelper.decodeToken(
            parsedResult.idToken
          );
          if (
            this.authGuard.checkNXTsoftUserGroup(
              route.snapshot.data.roles,
              decodedIdToken
            )
          ) {
            this.authService.setUserData(
              parsedResult.idToken,
              parsedResult.accessToken,
              parsedResult.refreshToken
            );
            // this.handleAvatar(decodedIdToken['picture']);
          } else {
            this.router.navigate(['/login']);
          }
        }
      })
      .catch(result => {
        console.log('error result', result);
        this.router.navigate(['/login']);
      });

      this.setSessionTimeOut();
      this.userInactive.subscribe(() =>
        this.logout()
      );
  }

  ngOnInit() {
    // Auth.configure({ storage: window.sessionStorage });
    this.debouncedRefreshUserState = this.debouncedRefreshUserState.bind(this);
  }

  title = 'ground-control-web-app';

  // This will check if user is idle for 15 minutes
  setSessionTimeOut() {
    this.userActivity = setTimeout(
      () => this.userInactive.next(undefined),
      900000
    );
  }

  // This will check if the user is inactive on Pulse window(500ms delay)
  @HostListener('window:mousemove')
  debouncedRefreshUserState = debounce(() => {
    clearTimeout(this.userActivity);
    this.setSessionTimeOut();
  }, 500);

  logout() {
    console.log('idle page logout');
    this.amplifyService.logout();
    this.authService.isLoggedIn = false;
    this.authGuard.removeData();
    this.router.navigate(['login']);
  }

  handleAvatar(avatar: any) {
    console.log(avatar);
    // If the user has an avatar
    if (avatar) {
      if (
        this.amplifyService.isNXTsoftDev ||
        this.amplifyService.isNXTsoftOperations ||
        this.amplifyService.isAdmin ||
        this.amplifyService.isNXTsoftSales ||
        this.amplifyService.isSuperAdmin || 
        this.amplifyService.isLoanServicingAdmin ||
        this.amplifyService.isLoanSystemsAdmin
      ) {
        this.avatarService.setAvatar('admin_user_profile1');
      } else {
        this.avatarService.setAvatar('basic');
      }
    } else {
      // If user doesnt set their avatar as basic for default
      const defaultAvatar = 'basic';
      // Update their avatar in cognito
      this.avatarService.updateAvatar(defaultAvatar).subscribe(result => {
        if (result) {
          // Set the avatar
          this.avatarService.setAvatar(defaultAvatar);
        }
      });
    }
  }
}
