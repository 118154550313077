import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AssetService } from 'src/@ccmc/services/asset.service';
import { DocumentConnectorService } from 'src/@ccmc/services/doc-connector.service';
import { CCMCSelectedFieldService } from 'src/@ccmc/services/selected-field.service';
import { AmplifyService } from 'src/@ccmc/services/amplify.service';
import { STATUS_MESSAGES, STATUS_VALUES } from '../constants';

@Component({
  selector: 'app-selected-loan',
  templateUrl: './selected-loan.component.html',
  styleUrls: ['./selected-loan.component.scss']
})
export class SelectedLoanComponent implements OnInit {
  dcDoc: any;
  selectedFile: any;
  selectedLoan: any;
  private selectedSub: Subscription;
  private dcSub: Subscription;
  private isNXTSoftUser = false;
  selectedLoanDisplayedColumns = [
    'fileName',
    'source',
    'destination',
    'statusMessage'
  ];
  selectedLoanDataSource: any;
  unsubscribe: Subject<any> = new Subject();
  destinations: any;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  constructor(
    private dcService: DocumentConnectorService,
    private assetService: AssetService,
    private dialog: MatDialog,
    private selectedFieldService: CCMCSelectedFieldService,
    private amplifyService: AmplifyService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getData();
    this.validateNXTSoftUser();
  }

  private async validateNXTSoftUser() {
    if (await this.amplifyService.checkNXTsoftUserGroup()) {
      this.isNXTSoftUser = true;
    }
  }

  onSelectFile(selectedFile: any) {
    this.selectedFile = selectedFile;
  }

  getData() {
    this.selectedSub = this.selectedFieldService.onHistoryLoanFieldSelected
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(selected => {
        console.log('Selected History Loan', selected);
        this.selectedLoan = selected;
        if (this.selectedLoan && this.selectedLoan.files) {
          this.selectedLoanDataSource = new MatTableDataSource(
            this.selectedLoan.files
          );
          // initializes sort
          this.selectedLoanDataSource.sort = this.sort;
          this.selectedLoanDataSource.sortingDataAccessor = (item: any, property: any) => {
            switch (property) {
              case 'fileName': return new String(item.fileName);
              case 'source': return new String(item.source);
              case 'destination': return new String(item.destination);
              case 'statusMessage': return new String(item.statusMessage);
              default: return item[property];
            }
          };
        } else {
          this.selectedLoanDataSource = new MatTableDataSource([]);
          // initializes sort
          this.selectedLoanDataSource.sort = this.sort;
          this.selectedLoanDataSource.sortingDataAccessor = (item: any, property: any) => {
            switch (property) {
              case 'fileName': return new String(item.fileName);
              case 'source': return new String(item.source);
              case 'destination': return new String(item.destination);
              case 'statusMessage': return new String(item.statusMessage);
              default: return item[property];
            }
          };
        }
      });

    this.dcSub = this.dcService.dcDocument
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dcDocument => {
        if (dcDocument) {
          if (dcDocument.destinations) {
            this.destinations = dcDocument.destinations;
          }
        } else {
          this.destinations = undefined;
        }
      });
  }

  getStatus(statusMessage: string): string {
    const lowerCaseStatusMessage = statusMessage.toLowerCase();
  
    // Check if the status message matches the relevant conditions
    const isRelevantMessage =
      lowerCaseStatusMessage.includes(STATUS_MESSAGES.DOCUMENT_RETRIEVED) ||
      lowerCaseStatusMessage.includes(STATUS_MESSAGES.PROCESSED_FILE) ||
      lowerCaseStatusMessage.includes(STATUS_MESSAGES.SENDING_FILE);
  
    // Convert selectedLoan.date to a Date object if it's a string, or use it directly if it's a timestamp
    const loanDateInMs = typeof this.selectedLoan.date === 'string' ? new Date(this.selectedLoan.date).getTime() : this.selectedLoan.date;
    const oneHourAgo = Date.now() - 60 * 60 * 1000; // Calculate the time one hour ago in milliseconds
  
    // Check if the loan date is older than one hour
    const isOlderThanOneHour = loanDateInMs < oneHourAgo;

    // Additional conditions for checking specific messages after one hour
    const isStatusAllowedAfterHour =
      lowerCaseStatusMessage.includes(STATUS_MESSAGES.LOAN_FILE_EXPORTED);

    if (isStatusAllowedAfterHour) {
      return statusMessage;
    }

    // Determine the status to return
    if (!isRelevantMessage || isOlderThanOneHour && isRelevantMessage || this.selectedLoan?.status == STATUS_VALUES.UNSUCCESSFUL) {
      return 'Failed to transfer loan file. Please review logs for more details.';
    }

    return statusMessage;
  }

  getDetailedStatus(statusMessage: string): string {
    if (!this.isNXTSoftUser) {
      return "";
    }
    const lowerCaseStatusMessage = statusMessage.toLowerCase();
  
    // Check if the status message matches the relevant conditions
    const isRelevantMessage =
      lowerCaseStatusMessage.includes(STATUS_MESSAGES.DOCUMENT_RETRIEVED) ||
      lowerCaseStatusMessage.includes(STATUS_MESSAGES.PROCESSED_FILE) ||
      lowerCaseStatusMessage.includes(STATUS_MESSAGES.SENDING_FILE);

    if (lowerCaseStatusMessage.includes(STATUS_MESSAGES.LOAN_FILE_EXPORTED) || lowerCaseStatusMessage.includes('please review logs')) {
      return "";
    }
  
    // Convert selectedLoan.date to a Date object if it's a string, or use it directly if it's a timestamp
    const loanDateInMs = typeof this.selectedLoan.date === 'string' ? new Date(this.selectedLoan.date).getTime() : this.selectedLoan.date;
    const oneHourAgo = Date.now() - 60 * 60 * 1000; // Calculate the time one hour ago in milliseconds
  
     // Check if the loan date is older than one hour
     const isOlderThanOneHour = loanDateInMs < oneHourAgo;

     if (!isRelevantMessage) {
      return statusMessage;
     }

    if (!isRelevantMessage || isOlderThanOneHour && isRelevantMessage || this.selectedLoan?.status == STATUS_VALUES.UNSUCCESSFUL) {
      return `Failed to update DynamoDB or the Lambda function may have timed out. Last recorded status message: "${statusMessage}"`;
    }
  
    return "";
  }
  

  goToSelectedLoansDestination(selectedFile: any) {
    for (let destination of this.destinations) {
      if (selectedFile.destination === destination.id) {
        this.selectedFieldService.onDestinationFieldSelected.next(destination);
        this.router.navigate(['/doc-connector/source-and-destination']);
      }
    }
  }
}
