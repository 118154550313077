import { Injectable } from '@angular/core';
import { PrecisionService } from './precision.service';
import { SignatureService } from './signature.service';
import { GCSettingsService } from './gcsettings.service';
import { PremierService } from './premier.service';
import { JxchangeService } from './jxchange.service';
import { BridgeService } from './bridge.service';
import { AssetService } from './asset.service';
import { IBSService } from './ibs.service';
import { OpenDCIService } from './opendci.service';
import { MortgageServicerService } from './mortgageservicer.service';
import { DNAService } from './dna.service';
import { CleartouchService } from './cleartouch.service';
import { MiserService } from './miser.service';
import { SpectrumService } from './spectrum.service';
import { InsightService } from './insight.service';
import { HorizonXchangeService } from './horizon-xchange.service';
import { PhoenixService } from './phoenix.service';
import { XP2Service } from './xp2.service';
import { CenlarService } from './cenlar.service';
import { DMIService } from './dmi.service';
import { PorticoService } from './portico.service';
import { KeystoneService } from './keystone.service';
import { DefiServicingService } from './defiservicing.service';
import { SymxchangeService } from './symxchange.service';
import { PremierCAService } from './premierca.service';
import { PrologueService } from './prologue.service';
import { UltraDataService } from './ultradata.service';
import { SynergentSymitarService } from './synergent-symitar.service';
import { LoanServService } from './loanserv.service';
import { PremierCOService } from './premierco.service';
import { IBSCodeConnectService } from './ibs-codeconnect.service';
import { MeridianService } from './meridian.service';
@Injectable({
  providedIn: 'root'
})
export class ActiveCoreService {
  /**
   * Active Core
   *
   * @memberof ActiveCoreService
   */
  activeCore: any;

  /**
   * Active Service
   * @description reference to the current core service
   * @memberof ActiveCoreService
   */
  activeService: any;

  /**
   * Creates an instance of ActiveCoreService.
   * @param {PrecisionService} precisionService
   * @param {SignatureService} signatureService
   * @param {GCSettingsService} gcSettingsService
   * @param {PremierService} premierService
   * @param {JxchangeService} jxchangeService
   * @param {BridgeService} bridgeService
   * @param {AssetService} assetService
   * @param {OpenDCIService} openDCIService
   * @param {IBSService} ibsService
   * @memberof ActiveCoreService
   */
  constructor(
    private precisionService: PrecisionService,
    private signatureService: SignatureService,
    private gcSettingsService: GCSettingsService,
    private premierService: PremierService,
    private jxchangeService: JxchangeService,
    private bridgeService: BridgeService,
    private assetService: AssetService,
    private openDCIService: OpenDCIService,
    private ibsService: IBSService,
    private dnaService: DNAService,
    private mortgageservicerService: MortgageServicerService,
    private cleartouchService: CleartouchService,
    private miserService: MiserService,
    private spectrumService: SpectrumService,
    private insightService: InsightService,
    private horizonXchangeService: HorizonXchangeService,
    private phoenixService: PhoenixService,
    private xp2Service: XP2Service,
    private cenlarService: CenlarService,
    private dmiService: DMIService,
    private proticoService: PorticoService,
    private keystoneService: KeystoneService,
    private defiServicingService: DefiServicingService,
    private symxchangeService: SymxchangeService,
    private premierCAService: PremierCAService,
    private prologueService: PrologueService,
    private ultraDataService: UltraDataService,
    private synergentSymitarService: SynergentSymitarService,
    private loanServService: LoanServService,
    private premierCOService: PremierCOService,
    private ibsCodeConnectService: IBSCodeConnectService,
    private meridianService: MeridianService
  ) {}

  /**
   * Get Active Core
   * @description gets the current core and sets the core if not already
   * @memberof ActiveCoreService
   */
  getActiveCore() {
    if (this.activeCore !== null) {
      this.selectCore();
    } else {
      const assetId = this.assetService.getSelectedAssetId();
      this.gcSettingsService.retrieveSettings(assetId).subscribe(response => {
        this.activeCore = response.core;
        this.selectCore();
      });
    }
  }
  reportCore() {
    return this.activeCore;
  }
  /**
   * Select Core
   * @description sets the active service based upon the active core
   * @memberof ActiveCoreService
   */
  selectCore() {
    this.activeCore = this.activeCore.toLowerCase();
    switch (this.activeCore) {
      case '2020':
        this.activeService = this.jxchangeService;
        break;
      case 'symitar':
        this.activeService = this.symxchangeService;
        break;
      case 'synergent-symitar':
        this.activeService = this.synergentSymitarService;
        break;
      case 'bridge':
        this.activeService = this.bridgeService;
        break;
      case 'cleartouch':
        this.activeService = this.cleartouchService;
        break;
      case 'coredirector':
        this.activeService = this.jxchangeService;
        break;
      case 'dna':
        this.activeService = this.dnaService;
        break;
      case 'insight':
        this.activeService = this.insightService;
        break;
      case 'ibs':
        this.activeService = this.ibsService;
        break;
      case 'miser':
        this.activeService = this.miserService;
        break;
      case 'mortgageservicer':
        this.activeService = this.mortgageservicerService;
        break;
      case 'opendci':
        this.activeService = this.openDCIService;
        break;
      case 'precision':
        this.activeService = this.precisionService;
        break;
      case 'premier':
        this.activeService = this.premierService;
        break;
      case 'premierca':
        this.activeService = this.premierCAService;
        break;
      case 'premierco':
        this.activeService = this.premierCOService;
        break;
      case 'prologue':
        this.activeService = this.prologueService;
        break;
      case 'signature':
        this.activeService = this.signatureService;
        break;
      case 'silverlake':
        this.activeService = this.jxchangeService;
        break;
      case 'spectrum':
        this.activeService = this.spectrumService;
        break;
      case 'horizon-xchange':
        this.activeService = this.horizonXchangeService;
        break;
      case 'phoenix':
        this.activeService = this.phoenixService;
        break;
      case 'xp2':
        this.activeService = this.xp2Service;
        break;
      case 'cenlar':
        this.activeService = this.cenlarService;
        break;
      case 'dovenmuehle':
        this.activeService = this.dmiService;
        break;
      case 'portico':
        this.activeService = this.proticoService;
        break;
      case 'keystone':
        this.activeService = this.keystoneService;
        break;
      case 'defiservicing':
        this.activeService = this.defiServicingService;
        break;
      case 'ultradata':
        this.activeService = this.ultraDataService;
        break;
      case 'loanserv':
        this.activeService = this.loanServService;
        break;
      case 'ibs-codeconnect':
        this.activeService = this.ibsCodeConnectService;
        break;
      case 'meridian':
        this.activeService = this.meridianService;
        break;
    }
  }

  /**
   * Setter for active core
   * @description sets the active core
   * @param {*} core
   * @memberof ActiveCoreService
   */
  setActiveCore(core: any) {
    this.activeCore = core;
  }
}
