export const STATUS_MESSAGES = {
    DOCUMENT_RETRIEVED: 'document retrieved from encompass',
    PROCESSED_FILE: 'processed file contents. adding to zip.',
    SENDING_FILE: 'sending file',
    LOAN_FILE_EXPORTED: 'loan file exported successfully',
};

export const STATUS_VALUES = {
    SUCCESSFUL: 'Successful',
    QUEUED: 'Queued',
    UNSUCCESSFUL: 'Unsuccessful',
  };
  

export const TIME_THRESHOLD_MS = 600000;