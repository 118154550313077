<div class="selected-loan-table-section">
  <div class="selected-loan-filter-container">
    <mat-form-field class="filter-form-field">
      <input
        matInput
        style="background-color: inherit"
        placeholder="Filter Search"
        #filterSearch
      />
    </mat-form-field>
  </div>
  <div #container class="selected-loan-table-container">
    <table
      class="table"
      mat-table
      matSort
      #table
      [dataSource]="selectedLoanDataSource"
      CCMCPerfectScrollbar
    >
      <!-- File Name Column -->
      <ng-container matColumnDef="fileName">
        <th mat-sort-header mat-header-cell *matHeaderCellDef>File Name</th>
        <td mat-cell *matCellDef="let element" class="noselect">
          {{ element.fileName }}
        </td>
      </ng-container>

      <!-- Source Column -->
      <ng-container matColumnDef="source">
        <th mat-sort-header mat-header-cell *matHeaderCellDef>Source</th>
        <td mat-cell *matCellDef="let element" class="noselect">
          {{ element.source }}
        </td>
      </ng-container>

      <!-- Destination Column -->
      <ng-container matColumnDef="destination">
        <th mat-sort-header mat-header-cell *matHeaderCellDef>Destination</th>
        <td mat-cell *matCellDef="let element" class="noselect">
          {{ element.destination }}
        </td>
      </ng-container>

      <!-- Message Column -->
      <ng-container matColumnDef="statusMessage">
        <th mat-sort-header mat-header-cell *matHeaderCellDef>Message</th>
        <td mat-cell *matCellDef="let element" class="noselect"  [matTooltip]="getDetailedStatus(element.statusMessage)">
          {{ getStatus(element.statusMessage) }}
        </td>
      </ng-container>

      <!-- Message Column
      <ng-container matColumnDef="goToDestination">
        <th
          mat-sort-header
          mat-header-cell
          *matHeaderCellDef
          class="goToDestinationButton"
        ></th>
        <td mat-cell *matCellDef="let element" class="noselect">
          <mat-icon
            class="goToDestinationButton"
            matTooltip="Navigate to the Destinations screen."
            matTooltipClass="tooltip-color"
            style="cursor: pointer"
            (click)="goToSelectedLoansDestination(element)"
          >
            double_arrow</mat-icon
          >
        </td>
      </ng-container> -->

      <tr
        mat-header-row
        *matHeaderRowDef="selectedLoanDisplayedColumns; sticky: true"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: selectedLoanDisplayedColumns"
        [ngClass]="{ 'accent-50-bg': row == selectedFile }"
      ></tr>
    </table>
  </div>
</div>
